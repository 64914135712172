@use 'styles/colors';
@use 'styles/borders';

.sidePanel {
    position: absolute;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    top: 0;
    right: 0;
    white-space: nowrap;
    background-color: colors.$base-white;
    box-shadow: -6px 4px 6px 0px rgba(0, 0, 0, 0.1);
    z-index: 100;
    transition: 0.5s;

    .fixedWidth {
        // sets min width so as panel shrinks, it doesn't "squish" content
        min-width: 27.5rem;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;
    }

    .heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        @extend %thin-bottom;

        button {
            background-color: unset;
            border: none;
            padding: 0;
            cursor: pointer;
        }
    }

    .panelContent {
        display: flex;
        flex-grow: 1;
        overflow: hidden;
    }

    .buttonBar {
        display: flex;
        justify-content: flex-end;
        padding: 1rem;
        gap: 0.5rem;
        @extend %thin-top;
    }
}
