.content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .operators {
        display: flex;
        gap: 0.5rem;
    }
}
