.results {
    height: 100%;

    .loader {
        height: 80%;
        width: 80%;
    }

    main {
        overflow: auto;
    }
}
