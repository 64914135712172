@use 'styles/colors';
@use 'styles/components';

$error-border-width: 4px;

@mixin highlight($color, $border-width: $error-border-width) {
    border-left-color: $color;

    input,
    select,
    textarea {
        width: 100%;
        outline: none;

        border-width: $border-width;
        border-style: solid;
        border-color: $color;
    }
}

.entry {
    display: flex;
    width: 100%;
    box-sizing: border-box;

    border-left-width: 4px;
    border-left-style: solid;
    border-left-color: colors.$clear;

    line-height: normal;

    label {
        margin: 0;
        color: colors.$base-darkest;
        font-size: var(--component-font-size);
    }

    input,
    select,
    textarea,
    .multi-select {
        width: 100%;
        box-sizing: border-box;
        margin: 0;
        font-size: var(--component-font-size);
        height: var(--component-height);
    }

    &.small {
        @extend %small;

        select {
            padding: 0.25rem 0.5rem;
        }

        input {
            padding: 0.25rem 0.5rem;
        }

        textarea {
            @extend %small-textarea;
        }
    }

    &.medium {
        @extend %medium;

        textarea {
            @extend %medium-textarea;
        }
    }

    &.large {
        @extend %large;

        textarea {
            @extend %large-textarea;
        }
    }

    &.error,
    &.warn {
        input,
        select {
            //  Adjusts the sizing of select and input components to account for a thicker border.
            min-height: calc(var(--component-height) + ($error-border-width * 2));
        }
    }

    &.error {
        @include highlight(colors.$error-dark);
    }

    &.warn {
        @include highlight(colors.$warning);
    }
}
