@mixin sizedDatePicker($backgroundSize: 1rem, $calendarWidth: 17.75rem, $calendarHeight: 16.75rem) {
    & > div > button {
        background-size: $backgroundSize;
        background-position: left;
        width: $backgroundSize;

        &:focus {
            width: calc($backgroundSize + 0.5rem);
            background-position: center;
        }
    }

    & > div > input {
        margin-right: 0.25rem !important;
    }

    & > div > div {
        min-width: $calendarWidth !important;
        min-height: $calendarHeight !important;
    }
}

.small {
    @include sizedDatePicker(1rem, 17.75rem, 15rem);

    --calendar-navigation-padding: 18px;
    --calendar-day-of-week-padding: 4px;
    --calendar-date-padding: 8px;
}

.medium {
    @include sizedDatePicker(1.125rem);
}

.large {
    @include sizedDatePicker(1.25rem);
}
