.criteria {
    display: flex;
    flex-direction: column;

    gap: 1rem;

    padding: 1rem;

    &.small {
        gap: 0.5rem;
    }
}
