.checkboxGroup {
    .fieldSet {
        padding: 0.125rem 0;
        margin: 0;
        border: none;
    }

    .verticalOptions {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        flex-wrap: wrap;
    }

    .horizontalOptions {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        label {
            font-weight: normal !important;
        }
    }
}
