.entry {
    flex-direction: column;
    gap: 0.25rem;

    &.alert {
        padding-left: 1.25rem;
    }

    .textContainer {
        display: flex;
        flex-direction: column;
        gap: 0.125rem;
    }
}
